import React from "react";
import Flex from "app/components/Flex";
import { Edit, PlusBox, Trash } from "app/components/Icon";
import { DropdownMenu, DropdownOption } from "app/components/Dropdown";
import { MenuButton } from "./MenuButton";

interface Props {
  openAddClassesModal(): void;
  openEditPlaylistModal(): void;
  openDeletePlaylistModal(): void;
}

export function DropdownMenuOptions({
  openAddClassesModal,
  openEditPlaylistModal,
  openDeletePlaylistModal,
}: Props) {
  return (
    <Flex>
      <DropdownMenu menuButton={MenuButton}>
        <DropdownOption
          Svg={PlusBox}
          onClick={openAddClassesModal}
          text="Add Classes"
        />
        <DropdownOption
          Svg={Edit}
          onClick={openEditPlaylistModal}
          text="Edit Details"
        />
        <DropdownOption
          Svg={Trash}
          onClick={openDeletePlaylistModal}
          text="Delete Playlist"
        />
      </DropdownMenu>
    </Flex>
  );
}
